import { Alert, AlertTitle, Button, Grid } from "@mui/material"
import { useEffect, useState } from "react"
import { AdministrationEndpointService } from "../services/administration-endpoint.service";
import { AdminMesage, MessageType } from "../models/admin-message";
import { useMsal } from "@azure/msal-react";
import React from "react";
import { useCookies } from 'react-cookie';

export function AdminMessages() {
    const msalConfig = useMsal();
    const [adminMessages, setAdminMessages] = useState<AdminMesage[] | null>();
    const [cookies, setCookie] = useCookies();

    useEffect(() => {
        getAdminMessages();
    }, [])

    const getAdminMessages = async () => {
        let messages = await AdministrationEndpointService.getMessages(msalConfig);

        if (messages != null) {
            //#region added code in https://dev.azure.com/gt-se/GTSE/_workitems/edit/19058. Please remove this after es-tools url is no more valid.!
            const url: string = window.location.href;
            const esToolsMessageInfo = "[ES-TOOLS]";

            if (!url.includes("es-tools")) {
                messages = messages.filter(message => !message.title.includes(esToolsMessageInfo));
            } else {
                messages = messages.map(message => ({
                    ...message,
                    title: message.title.replace(esToolsMessageInfo, "")
                }));
            }

            //#endregion

            const adminMessages = localStorage.getItem("adminMessages");
            if (adminMessages != null) {
                messages = messages.filter(x => !adminMessages?.includes(x.id) || !x.readOnlyOnce);
            }
        }
        setAdminMessages(messages);
    }

    const closeAdminMessage = (adminMessageId: string) => {
        const newAdminMesageList = [...adminMessages ?? []];
        const message = adminMessages?.find(x => x.id == adminMessageId);
        if (message?.readOnlyOnce) {
            updateAdminMessagesInLocalStorage(adminMessageId);
            setCookie(message.id, message.title);
        }
        setAdminMessages(newAdminMesageList.filter(x => x.id != adminMessageId));
    }

    //better to use cookies instead but problem with creadential and origin
    const updateAdminMessagesInLocalStorage = (adminMessageId: string) => {
        let adminMessages = localStorage.getItem("adminMessages");
        if (adminMessages == null) {
            adminMessages = adminMessageId;
        }
        else {
            adminMessages = adminMessages.concat(`, ${adminMessageId}`);
        }
        localStorage.setItem("adminMessages", adminMessages);
    };

    const openNewWindow = (url: string) => {
        window.open(url, "_blank");
    }

    return (
        <React.Fragment>
            {adminMessages && adminMessages.length > 0 &&
                adminMessages.map((message, index) => {
                    return (<Alert key={index} sx={{
                        '& .MuiAlert-message': { width: "100%", maxWidth: "2000px" },
                        '& .MuiAlert-icon': { alignItems: "center" },
                        '& .MuiAlert-action': { alignItems: "center" }
                    }

                    } severity={message.type == MessageType.Alert ? "error" : "info"} onClose={(event) => closeAdminMessage(message.id)}>

                        <Grid container>
                            <Grid item md={10}>
                                <AlertTitle>{message.title}</AlertTitle>
                                {message.content}
                            </Grid>
                            <Grid item md={2} sx={{ alignItems: "center", height: "inherit", display: "flex" }}>
                                {
                                    message.link && message.link != "" &&
                                    <Button variant="contained" onClick={() => openNewWindow(message.link)}>{message.linkText}</Button>
                                }
                            </Grid>
                        </Grid>
                    </Alert>)
                })
            }
        </React.Fragment >
    )
}