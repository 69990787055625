import { Container, Box, Grid, Button, styled, AccordionDetails } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import Notifications from "../components/notifications";
import { useNavigate } from "react-router-dom"
import GtLoadingSpinner from "../components/spinner";
import { useTranslation } from "react-i18next";
import OrganizationsDropdown from "../components/organizations-dropdown";
import { useOrganizationAccessStore } from "../stores/organization-access.store";
import { CustomerAdminsView } from "../components/customer-admins";

function StartPage() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const selectOrganization = useOrganizationAccessStore(state => state.selectOrganization);
    const [organizationNumber, setOrganizationNumber] = useState<string | null>(null);

    useEffect(() => {
        selectOrganization(null);
        document.title = "BCS-Månadsavstämning";
    })

    const getOrganizationDetails = useCallback(async (selectedOrganizatioNumber: string) => {
        if (selectedOrganizatioNumber == null || selectedOrganizatioNumber == "") {
            setOrganizationNumber(null);
            return;
        }

        if (organizationNumber != selectedOrganizatioNumber) {
            setOrganizationNumber(selectedOrganizatioNumber);
        }
    }, [organizationNumber])

    const goToOrganizationPage = async () => {
        navigate(`${organizationNumber ?? ""}`);

    }

    return (
        <React.Fragment>
            <Notifications pageName="selectOrganization" key={"selectOrganizationNotification"}></Notifications>
            <Container style={{ marginTop: "20px", marginBottom: "40px" }}>
                <Box sx={{ border: "solid rgb(214 212 208) 1px", padding: "16px", marginBottom: "50px", backgroundColor: "rgb(250, 249, 248)" }}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <h3>
                                {t("MonthySalaryPage.StartPage.Title")}
                            </h3>
                            {t("MonthySalaryPage.StartPage.Description")}
                        </Grid>
                    </Grid>

                    <Grid container spacing={1} style={{ marginTop: "30px" }}>
                        <Grid item xs={12} md={4} lg={3} xl={3}>
                            <OrganizationsDropdown onSelectOrganization={getOrganizationDetails} />
                            {organizationNumber &&
                                <React.Fragment>
                                    <Grid container spacing={2} style={{ marginTop: "10px" }}>
                                        <Grid item xs={6}></Grid>
                                        <Grid item xs={6}>
                                            <Button variant="contained" sx={{ width: "100%" }} onClick={goToOrganizationPage}>
                                                {t("Common.Continue")}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </React.Fragment>
                            }
                        </Grid>
                    </Grid>
                </Box>
                <CustomerAdminsView></CustomerAdminsView>
            </Container>
        </React.Fragment >)
}

export default StartPage;