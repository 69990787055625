import { Autocomplete, InputLabel, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { AccessEndpointService } from "../services/access-endpoint.service";
import { useMsal } from "@azure/msal-react";
import { UserGraph } from "../models/organization";
import React from "react";

function PersonPicker(props: { onChange: (userEmail: string) => void, existedEmails: string[] }) {
    const { t } = useTranslation();
    const [options, setOptions] = React.useState<UserGraph[]>([]);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const msalContext = useMsal();

    const getUsers = async (value: string) => {
        if (value && value.length > 3) {
            setIsLoading(true);
            const usersFromES = await AccessEndpointService.getESUsers(value, msalContext);

            setOptions(usersFromES.filter(x => !props.existedEmails.some(y => y == x.email)));
            setIsLoading(false);
        }
        else {
            setOptions([]);
        }
    }

    return (
        <React.Fragment>
            <InputLabel id="person-picker-label">{t("AccessPage.PickerLabel")}</InputLabel>
            <Autocomplete
                sx={{
                    backgroundColor: "white",
                }}
                onClose={() => {
                    setOptions([])
                }}
                onChange={(event, value) => props.onChange(value?.email ?? "")}
                isOptionEqualToValue={(option, value) => option.email === value.email}
                size="small"
                disablePortal
                id="combo-box-demo"
                options={options}
                getOptionLabel={(option) => option.email}
                loading={isLoading}
                renderInput={(params) => <TextField {...params} onChange={(event) => getUsers(event.target.value)} />}
            />
        </React.Fragment>
    )
}

export default PersonPicker;